<template>

<app-wrapper class="quotes">

	<h2>{{ $state.page.quotes_title }}</h2>
	
	<app-wrapper class="quotes-wrapper" :content="true">

		<i class="fa-solid fa-angle-left quotes-left" v-on:click="onBackClick"></i>
		<i class="fa-solid fa-angle-right quotes-right" v-on:click="onForwardClick"></i>	

		<app-wrapper v-for="(item, index) in items" :key="index" class="quotes-item" :class="{'is-active': index === active, 'is-left': index < active, 'is-right': index > active}">

			<p>{{ item.text }}</p>

			<small><b>{{ item.name }}</b> {{ item.role }}</small>

		</app-wrapper>

	</app-wrapper>

	<div class="quotes-pips">
		<div v-for="(item, index) in items" :key="index" class="quotes-pips-item" :class="{'is-active': index === active}" v-on:click="onJumpClick(index)" />
	</div>

</app-wrapper>

</template>

<script>

export default {

	data: function() {

		return {
			active: 0,
			delay: 10000,
			direction: 'forward'
		}

	},

	created: function() {

		this.timer = this.$_.delay(this.onAutomateNext.bind(this), this.delay)

	},

	beforeDestroy: function() {

		clearTimeout(this.timer)

	},

	computed: {

		items: function() {

			return this.$state.page.quotes_items

		}

	},

	methods: {

		onAutomateNext: function() {

			if (this.direction === 'forward') {

				this.onForwardClick()

			} else {

				this.onBackClick()

			}

		},

		onBackClick: function() {

			clearTimeout(this.timer)

			this.active = (this.active > 0) ? this.active - 1 : this.items.length - 1

			this.direction = 'back'

			this.timer = this.$_.delay(this.onAutomateNext.bind(this), this.delay)

		},

		onForwardClick: function() {

			clearTimeout(this.timer)

			this.active = (this.active < this.items.length - 1) ? this.active + 1 : 0

			this.direction = 'forward'

			this.timer = this.$_.delay(this.onAutomateNext.bind(this), this.delay)

		},

		onJumpClick: function(index) {

			clearTimeout(this.timer)

			this.active = index 

			this.direction = 'forward'

			this.timer = this.$_.delay(this.onAutomateNext.bind(this), this.delay)

		}

	}

}

</script>

<style scoped>

.quotes {
	background-image: url(~@/assets/home.quotes.png);
	background-position: 50% 50%;
	background-size: cover;
	padding: 100px 0px 64px 0px;
	background-color: #BBD9CD;
}

.is-mobile .quotes {
	padding: 30px 0px 40px 0px;
}

.quotes-left,
.quotes-right {
	font-size: 16px;
	width: 40px;
	line-height: 40px;
	height: 40px;
	text-align: center;
	color: #112A45;
	position: absolute;
	left: 14px;
	top: -3px;
	cursor: pointer;
	z-index: 3;
	transition: all 100ms linear;
}

.is-mobile .quotes-left,
.is-mobile .quotes-right {
	top: calc(100% + 26px);
}

.quotes-right {
	right: 14px;
	left: auto;
}

.is-desktop .quotes-left:hover,
.is-desktop .quotes-right:hover {
	transform: scale(1.1);
}

.quotes-wrapper {
	z-index: 2;
	padding: 0px 64px;
}

.quotes-item {
	text-align: center;
	transition: all 300ms ease-in-out;
}

.quotes-item:not(.is-active) {
	position: absolute;
	top: 0px;
	transform: translateX(100%);
	opacity: 0;
}

.quotes-item.is-left:not(.is-active) {
	transform: translateX(-100%);
}

.quotes-item.is-right:not(.is-active) {
	transform: translateX(100%);
}

.quotes-item.is-active {
	position: relative;
	transform: translateX(0%);
	opacity: 1;
}

.quotes-item p {
	color: #112A45;
	font-size: 24px;
	line-height: 32px;
	font-weight: 200;
	margin-bottom: 20px;
}

.is-mobile .quotes-item p {
	font-size: 18px;
	line-height: 24px;
	font-weight: 300;
}

.quotes-item small {
	color: #112A45;
	font-size: 18px;
}

.is-mobile .quotes-item small {
	font-size: 14px;
}

.quotes-pips {
	display: flex;
	justify-content: center;
	margin-top: 100px;
}

.is-mobile .quotes-pips {
	margin-top: 40px;
}

.quotes-pips-item {
	border: 2px solid #112A45;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	margin: 0px 5px;
	transition: all 100ms linear;
	cursor: pointer;
}

.quotes-pips-item.is-active {
	border-color: #fff;
}

.is-desktop .quotes-pips-item:hover {
	transform: scale(1.1);
}

</style>
